import UsuarioModel from 'src/core/model/UsuarioModel'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import { LocalStorage, clone } from 'quasar'
import usuarioLoginStore from 'src/store/usuario-login/'
import RecuperarSenhaModel from 'src/model/usuario/RecuperarSenhaModel'
import FormCadastro from 'src/components/FormCadastro/FormCadastro.vue'
import GoogleLogin from 'vue-google-login'
import axios from 'axios'

export default {
  name: 'Login',
  components: { GoogleLogin, FormCadastro },
  mixins: [NotificacaoMixin],
  meta () {
    return {
      title: 'Login - NetCarros',
      description: { name: 'description', content: 'NetCarros, somos transparentes e seguros. Para ver as suas informações e gerenciar suas propostas nos carros usados e seminovos, acesse agora!' },
      property: { 'name': 'og:image', content: 'https://www.netcarros.com.br/seo/netcarros-login.jpg' },
      link: { material: { rel: 'canonical', href: 'https://www.netcarros.com.br' + this.$route.fullPath } },
      meta: [
        { 'property': 'og:title', 'content': 'Login - NetCarros' },
        { 'name': 'description', 'content': 'NetCarros, somos transparentes e seguros. Para ver as suas informações e gerenciar suas propostas nos carros usados e seminovos, acesse agora!' },
        { 'property': 'revisit-after', 'content': '5 days' },
        { 'property': 'og:image', 'content': 'https://www.netcarros.com.br/detalhes/full.png' },
        { 'property': 'og:image:secure_url', 'content': 'https://www.netcarros.com.br/detalhes/full.png' },
        { 'property': 'og:url', 'content': 'https://www.netcarros.com.br' + this.$route.fullPath },
        { 'property': 'og:site_name', 'content': 'NetCarros' },
        { 'property': 'fb:admins', 'content': '', 'vmid': 'fb:admins' },
        { 'property': 'og:locale', 'content': 'pt_BR' }
      ]
    }
  },
  data () {
    return {
      logado: LocalStorage.getItem('logado'),
      modelRecuperarSenha: new RecuperarSenhaModel(),
      modelRecuperarSenhaConfirmar: new RecuperarSenhaModel(true),

      salvando: false,
      requisitandoSenha: false,
      alterandoSenha: false,

      passosCredere: LocalStorage.getItem('passosCredereDetalhes'),
      bitrixdadoscadastro: LocalStorage.getItem('bitrixdadoscadastro'),

      // Redes Socias
      fbSignInParams: {
        scope: 'public_profile,email',
        return_scopes: true
      },

      paramsGoogleLogin: {
        client_id: '244983736592-6hlup8t7t01uijq8mqtj6vbn40mthr2u.apps.googleusercontent.com'
      },
      /* */

      usuarioModel: null,
      email: '',
      senha: '',
      recuperarEmail: '',
      tab: 'login',
      codigo: '',
      lembrar: true,
      tipoPassword: true,
      usuarioId: null,
      // reenviar
      tempoRestanteEmail: null,
      mostrarBotaoEmail: false
    }
  },
  watch: {
    tab (agora) {
      switch (agora) {
        case 'recuperar':
          setTimeout(() => {
            if (this.$refs.formEmail) {
              this.$refs.formEmail.resetValidation()
            }
          }, 1000)
          break
        case 'codigo':
          setTimeout(() => {
            if (this.$refs.formCodigo) {
              this.$refs.formCodigo.resetValidation()
            }
          }, 1000)
          break
        case 'senha':
          setTimeout(() => {
            if (this.$refs.formSenhas) {
              this.$refs.formSenhas.resetValidation()
            }
          }, 1000)
          break
        default:
          break
      }
    }
  },
  created () {
    if (this.$route.query.usuario_id && this.$route.query.email && this.$route.query.codigo) {
      const { usuario_id: usuarioId, email, codigo } = this.$route.query
      this.modelRecuperarSenhaConfirmar.form.email = email
      // eslint-disable-next-line camelcase
      this.usuarioId = usuarioId
      this.modelRecuperarSenhaConfirmar.form.codigo = codigo
      this.tab = 'senha'
    }
  },
  mounted () {
    if (!this.$store.hasModule('usuario-login')) {
      this.$store.registerModule('usuario-login', usuarioLoginStore)
    }
    this.usuarioModel = new UsuarioModel()

    if (this.passosCredere && this.passosCredere.id > 0) {
      this.notificacao('aviso', 'Para Continuamos você precisa entrar no sistema!')
    }

    if (this.bitrixdadoscadastro) {
      this.email = this.bitrixdadoscadastro.email
      this.senha = null
    }

    if (this.logado) {
      this.salvando = true
      this.cadastrando = true
      this.notificacao('aviso', 'Você já esta logado!', 5000)
      LocalStorage.remove('bitrixdadoscadastro')
      setTimeout(() => {
        if (this.passosCredere && this.passosCredere.id > 0) {
          console.log('painel-proposta', this.passosCredere)
          this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id } })
        } else {
          this.irRota({ name: 'painel-inicio' })
        }
      }, 2000)
    }
  },
  methods: {
    scrollcadastrar () {
      if (this.$refs.cadastro) {
        this.$refs.cadastro.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
      }
    },
    efetuarLogin () {
      const aviso = this.notificacao('enviando', 'Entrando...')
      this.salvando = true

      this.usuarioModel
        .login2({
          login: this.email,
          senha: this.senha,
          origem_whitelabel_id: 1
        })
        .then(retorno => {
          this.salvando = false
          aviso()

          if (retorno.erro === 0) {
            const notify = this.notificacao('salvo', 'Bem vindo!')
            this.$store.dispatch('usuario-login/setLogado', retorno.dados)
            LocalStorage.set('lembrarLogin', this.lembrar)
            axios.defaults.headers.common.Authorization =
              'Bearer ' + retorno.dados.token

            LocalStorage.remove('bitrixdadoscadastro')

            setTimeout(() => notify(), 3000)
            if (this.passosCredere && this.passosCredere.id && this.passosCredere.id > 0) {
              this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id } })
            } else {
              // this.$router.push('/painel')
              this.irRota({ name: 'painel-inicio' })
            }
          } else {
            this.notificacao('erro', retorno.msg)
          }
        })
        .catch(erro => {
          this.salvando = false
          aviso()
          console.error('erro', erro)
          this.notificacao('erro', 'Erro não identificado')
        })
    },
    contadorEmail (data) {
      let intervalo = setInterval(() => {
        var agora = new Date().getTime()
        var diferenca = data - agora
        var segundos = Math.floor((diferenca % (1000 * 60)) / 1000)
        this.tempoRestanteEmail = segundos

        if (diferenca < 0) {
          clearInterval(intervalo)
          this.mostrarBotaoEmail = true
        }
      }, 1000)
    },
    requisitarSenha (reenviar) {
      this.mostrarBotaoEmail = false
      this.requisitandoSenha = true
      const form = clone(this.modelRecuperarSenha.form)
      this.modelRecuperarSenha
        .salvar(form)
        .then(response => {
          let data = new Date()
          data.setSeconds(data.getSeconds() + 30)
          this.contadorEmail(data)
          this.requisitandoSenha = false
          this.usuarioId = response.dados.usuario_id
          switch (this.tab) {
            case 'codigo':
              if (!reenviar) {
                this.tab = 'senha'
              }
              break
            case 'recuperar':
              this.tab = 'codigo'
              break
            default:
              break
          }
        })
        .catch(error => {
          this.notificacao('erro', error.msg)
          this.requisitandoSenha = false
        })
    },

    validarSenha (senha = '') {
      if (!senha) senha = ''
      return senha.length < 1 || senha.length > 5
    },

    alterarSenha () {
      const form = Object.assign({}, this.modelRecuperarSenhaConfirmar.form)
      form.usuario_id = this.usuarioId
      this.alterandoSenha = true

      this.modelRecuperarSenhaConfirmar.include = 'roles,documento_lead'

      this.modelRecuperarSenhaConfirmar
        .salvar(form)
        .then((res) => {
          this.alterandoSenha = false
          const alteradoNotify = this.notificacao('aviso', 'Senha Alterado com sucesso!')

          const notify = this.notificacao('salvo', 'Bem vindo!')
          this.$store.dispatch('usuario-login/setLogado', res.dados)
          LocalStorage.set('lembrarLogin', this.lembrar)
          axios.defaults.headers.common.Authorization = 'Bearer ' + res.dados.token

          LocalStorage.remove('bitrixdadoscadastro')

          setTimeout(() => {
            alteradoNotify()
            notify()
          }, 3000)

          if (this.passosCredere && this.passosCredere.id > 0) {
            console.log('painel-proposta', this.passosCredere)
            this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id } })
          } else {
            this.irRota({ name: 'painel-inicio' })
          }
        })
        .catch(error => {
          this.notificacao('erro', error.msg)
          this.alterandoSenha = false
        })
    },

    // Login GOOGLE
    onSuccessLoginGoogle (googleUser) {
      const aviso = this.notificacao('enviando')
      this.enviando = true

      var profile = googleUser.getBasicProfile()
      var dadosGoogle = {
        google_id: profile.getId(),
        nome: profile.getName(),
        foto: profile.getImageUrl(),
        email: profile.getEmail()
      }

      this.usuarioModel.loginRedeSocial('google', dadosGoogle.google_id, dadosGoogle).then((res) => {
        aviso()
        this.salvando = false

        const retornoLaravel = res ? res.data : null

        if ([500, 400].indexOf(res.status) !== -1 || res.data.erro) {
          this.$router.push({ name: 'cadastro', params: { dados: dadosGoogle } })
          console.log('Não esta autenticado, ir para tela de cadastro')
        }

        // USUARIO GOOGLE JA CADASTRADO NO SISTEMA
        if ([200].indexOf(res.status) !== -1 && res.data.erro === 0) {
          const notify = this.notificacao('salvo', 'Bem vindo!')
          this.$store.dispatch('usuario-login/setLogado', retornoLaravel.dados)
          LocalStorage.set('lembrarLogin', this.lembrar)
          axios.defaults.headers.common.Authorization = 'Bearer ' + retornoLaravel.dados.token

          LocalStorage.remove('bitrixdadoscadastro')

          setTimeout(() => notify(), 3000)
          if (this.passosCredere && this.passosCredere.id > 0) {
            console.log('painel-proposta', this.passosCredere)
            this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id } })
          } else {
            this.irRota({ name: 'painel-inicio' })
          }
        }
        /* */
      }).catch(error => {
        aviso()
        this.notificacao('erro', error.msg)
      })
    },

    onFailureLoginGoogle (onFailure) {
      console.error('onFailureLoginGoogle:', onFailure)
      this.notificacao('erro', `Google Login Error: ${JSON.stringify(onFailure)}`)
    },
    /* */

    // Login FACEBOOK
    loginSuccessFacebook (response) {
      console.log('loginSuccessFacebook', response)
      const aviso = this.notificacao('enviando')
      this.enviando = true

      /* eslint-disable */
      FB.api('/me?fields=id,email,name', res => {
        var dadosFacebook = {
          facebook_id: res.id,
          nome: res.name,
          email: res.email
        }

        this.usuarioModel.loginRedeSocial('facebook', dadosFacebook.facebook_id, dadosFacebook).then((res) => {
          aviso()
          const retornoLaravel = res ? res.data : null

          if ([500, 400].indexOf(res.status) !== -1 || res.data.erro) {
            this.$router.push({ name: 'cadastro', params: { dados: dadosFacebook } })
            console.log('Não esta autenticado, ir para tela de cadastro')
          }

          // USUARIO FACEBOOK JA CADASTRADO NO SISTEMA
          if ([200].indexOf(res.status) !== -1 && res.data.erro === 0) {
            const notify = this.notificacao('salvo', 'Bem vindo!')
            this.$store.dispatch('usuario-login/setLogado', retornoLaravel.dados)
            LocalStorage.set('lembrarLogin', this.lembrar)
            axios.defaults.headers.common.Authorization = 'Bearer ' + retornoLaravel.dados.token

            LocalStorage.remove('bitrixdadoscadastro')

            setTimeout(() => notify(), 3000)
            if (this.passosCredere && this.passosCredere.id > 0) {
              this.irRota({ name: 'painel-proposta', params: { id: this.passosCredere.id } })
            } else {
              this.irRota({ name: 'painel-inicio' })
            }
          }
          /* */
        }).catch(error => {
          aviso()
          this.notificacao('erro', error.msg)
        })
      })
    },

    loginErrorFacebook (error) {
      let err = JSON.stringify(error)
      if (err.status === 'unknow') {
        this.notificacao('erro', `Não foi possivel conectar ao facebook: problema não informado`)
      } else {
        this.notificacao('erro', `Não foi possivel conectar ao facebook:  ${err}`)
      }
    },
    /* fim - Login FACEBOOK */
    irRota (rota) {
      this.$router.push(rota)
      // let routeData = this.$router.resolve(rota)
      // window.location.href = routeData.href
    }
  }
}
